import { PayForm } from "../components/PayForm/PayForm"
import './Index.css'

export const Index = () => {
    return(
    <div className="Index">
        <header>
            <a href="https://rvspace.ru">rvspace</a>
        </header>
        <main>
            <PayForm />
        </main>
        <footer>
            <a href="tel:+ 7 3412 223-100">+ 7 3412 223-100</a>
            <div className="info">
                <label className="primary">ООО "ИНТЕЛКАПИТАЛ"</label>
                <label className="secondary">ИНН 1841065840 / ОГРН 1161832075822</label>
                <label className="minor">услуги оказываются на основании <a href="https://rvspace.ru/pages/requisite.html">оферты</a></label>
            </div>
            <a href="mailto:sale@rvspace.ru">sale@rvspace.ru</a>
        </footer>
    </div>
    )
}