import { useEffect, useState } from "react"
import "./PayForm.css"
import { Box, Grid, TextField, ThemeProvider, Typography, createTheme } from "@mui/material"

export const PayForm = () => {

    let [formContent, setFromContent] = useState({
        "order": {
            "price": "",
            "currency": "",
            "title": "",
            "description": "",
            "url": "",
            "app": "",
        },
        "user": {
            "email": "",
            "phone": ""
        }
    })

    useEffect(() => {

        let url = new URL(window.location)
        let orderId = url.searchParams.get("orderId")
        let successId = url.searchParams.get("success")

        if (!orderId && !successId) {
            window.location = "https://rvspace.ru/"
        }

        if (orderId) {
            fetch(`/order/${orderId}`)
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        setFromContent(data)
                    }
                })
        }

        if (successId) {
            setFromContent(`Оплата по счёту ${successId} проведена успешно`)
        }
    }, [])

    const handleClick = (event) => {
        event.preventDefault()
        let body = JSON.stringify({
            orderId: new URL(window.location).searchParams.get("orderId")
        })
        console.log(body)
        fetch('/pay', {
            method: 'post',
            body: body,
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        })
        .then(res => res.json())
        .then(data => {
            if(data.pay){
                window.location = data.pay
            }
            else {
                console.log(data)
            }
        })
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#ffffff'
            }
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="PayForm">
                <Typography variant={"h5"}>{formContent.order.title}</Typography>
                <Typography variant={"subtitle1"}>{formContent.order.description}</Typography>
                {formContent.order.positions && <Grid container>
                    {formContent.order.positions.map(v => {
                        return [
                            <Grid item xs={10}><Typography variant="h6">{`${v.title}, кол-во: ${v.count}`}</Typography></Grid>,
                            <Grid><Typography variant="h6">{v.amount} р.</Typography></Grid>
                        ]
                    })
                    }
                </Grid>}

                <label className="sum">
                    <span>Сумма:</span>
                    <span>{formContent.order.price} {formContent.order.currency}</span>
                </label>

                <TextField 
                    variant="outlined" 
                    required 
                    label="E-mail плательщика" 
                    value={formContent.user?.email} 
                    size="small"
                    onChange={(event) => {
                        setFromContent({
                            ...formContent, user: {
                                ...formContent.user,
                                email: event.target.value
                            }
                        })
                    }}
                />

                <TextField 
                    variant="outlined" 
                    required 
                    label="Телефон плательщика" 
                    value={formContent.user?.phone} 
                    size="small"
                    onChange={(event) => {
                        setFromContent({
                            ...formContent, user: {
                                ...formContent.user,
                                phone: event.target.value
                            }
                        })
                    }}
                />

                <div className="wrapper">
                    <button className="button" onClick={handleClick}>Оплатить</button>
                </div>

            </div>
        </ThemeProvider>
    )
}